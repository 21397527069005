// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/hero-bg-ds.webp";
// Tech stack images
import java from "./assets/techstack/java.png";
import JavaScript from "./assets/techstack/icons8-javascript-96.png";
import python from "./assets/techstack/icons8-python-96.png";
import solidity from "./assets/techstack/icons8-solidity-96.png";
import Ethereum from "./assets/techstack/icons8-ethereum-96.png";
import react from "./assets/techstack/icons8-react-native-96.png";
import node from "./assets/techstack/icons8-nodejs-96.png";
import angluar from "./assets/techstack/icons8-angularjs-96.png";
import Bootstrap from "./assets/techstack/icons8-bootstrap-96.png";
import css from "./assets/techstack/icons8-css-96.png";
import html from "./assets/techstack/icons8-html5-96.png";
import mongodb from "./assets/techstack/icons8-mongodb-96.png";
import postgre from "./assets/techstack/icons8-postgre-sql-a-free-and-open-source-relational-database-management-system-96.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/icons8-npm-96.png";
import postman from "./assets/techstack/postman-icon.png";
// Porject Images
import projectImage1 from "./assets/projects/Ocean.JPG";
import projectImage2 from "./assets/projects/cosmos.JPG";
import projectImage3 from "./assets/projects/swap.JPG";
import projectImage4 from "./assets/projects/canadian.JPG";
import projectImage5 from "./assets/projects/evmos.JPG";
import projectImage6 from "./assets/projects/full-stack.JPG";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: " Edward Xian",
  tagline: "Full-StacK Developer",
  img: profile,
  about: `Results-driven and Highly-motivated Full Stack Developer with 8 + years of Industry experience in providing User Interface & User Experience Design and Development with most usable enterprise wide applications. Able to effectively self-manage during independent projects, as well as collaborate in a team. Specialized in Front End Development, Python Framework, and Mern Framework.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  github: "https://www.github.com/LoveNuna"
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Full-Stack Developer",
    Company: `Codepaper`,
    Location: "Toronto, ON",
    Type: "Full Time",
    Duration: "September 2020 – May 2023",
    Achievement: [
      'Learned CI/CD pipelines for web development from scratch using GitHub Actions, AWS CloudFormation, AWS SAM CLI and AWS Amplify.',
      'Developed RESTful APIs using Python, Django, Flask, FastAPI and Node/Express.',
      'Spearheaded the development of several small subprojects using Django + React.',
      'Designed and implemented database models and drivers for over 5 projects using PostgreSQL, MySQL, and Redis, MongoDB, AWS DynamoDB.',
      'Participated in development blockchain project using React, web3.js.',
      'Developed full-stack web applications which processed, analyzed, and rendered data visually.',
      'Integrated third-party APIs such as Stripe, Paypal, Twilio, Facebook, Twitter, Linkedin, AWS SDK, Zoho API, Hubspot, SendGrid, MailGun, Mandrill and MailChimp etc.'
    ],
  },
  {
    Position: "Full-Stack Developer ",
    Company: `Impact Interactive`,
    Location: "Hamilton, ON",
    Type: "Full Time",
    Duration: "October 2017 - July 2020",
    Achievement: [
      'Coordinated with the web development team that has 4 members to design user interfaces for client websites using HTML, JavaScript, React, Bootstrap.',
      'Mainly developed projects using Django, Flask, FastAPI.',
      'Implemented authentication/authorization mechanism using Auth0 and AWS Cogito.',
      'Constructed a Node.js notification server and front-end client that uses WebSocket to distribute notifications.',
      'Integrated 2 projects with PySpacy, Numpy & Pandas for NLP and other advanced features','Used AWS Serverless architecture (AWS Gateway & AWS Lamba) for the backend API development and built the Lambda functions using Python & Node.'
    ],
  },
  {
    Position: "Front-end Developer",
    Company: `WEBLiFT`,
    Location: "Nepan, ON",
    Type: "Full Time",
    Duration: "August 2015 - September 2017",
    Achievement: [
      'Build from reusable UI components reduces development time by 15%.',
      'Implemented dynamic reports reduces static report creation by 25%.',
      'Designed, built and automated data flows to save 10 hours of tedious work per week.',
      'Designed and implemented highly responsive components using React and Redux and built UI/UX using JavaScript, HTML5 and CSS3, ensuring cross-browser compatibility across 12 major providers based on Figma design.',
      'Designed over 10 charts to visualise complex data sets using D3.js.'
    ],
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "B.S. in Computer Science",
    Company: `University of Montreal`,
    Location: "Montreal, QB",
    Type: "Full Time",
    Duration: "September 2011 - May 2015",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  Java:java,  
  JAVAScript:JavaScript,
  Python:python,
  Solidity:solidity,
  ETHEreum:Ethereum,
  REact:react,
  Node:node,
  Angular:angluar,
  BootStrap:Bootstrap,
  CSS:css,
  HTML:html,
  MongoDB:mongodb,
  Postgre:postgre,
  VScode:vscode,
  Github:github,
  Git:git,
  NPM:npm,
  Postman:postman,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Data Market",
    image: projectImage1,
    description: `The app is a React app built with Next.js + TypeScript + CSS modules and will connect to Ocean remote components by default.`,
    techstack: " Next.js, TypeScript, CSS",
    previewLink: "https://market.oceanprotocol.com/",
    githubLink: "https://github.com/LoveNuna/data_market",
  },
  {
    title: "Evmos Block Explorer",
    image: projectImage2,
    description: `Big Dipper v2 is under active development and maintenance. Please visit Big Dipper v2 for any IBC-compatible chains.`,
    techstack: "React, Solidity, JavaScript, CSS",
    previewLink: "https://bigdipper.live/cosmos",
    githubLink: "https://github.com/LoveNuna/evmos-block-explorer",
  },
  {
    title: "Swap-kiwi",
    image: projectImage3,
    description: `Simple. Safe. Reliable. Your NFT swapping platform. `,
    techstack: "TypeScript, Solidity, Shell",
    previewLink: "https://swap.kiwi/",
    githubLink: "https://github.com/LoveNuna/swap-kiwi",
  },
  {
    title: "Cascadia-Chain",
    image: projectImage4,
    description: `Evmos is a scalable, high-throughput Proof-of-Stake blockchain that is fully compatible and interoperable with Ethereum. `,
    techstack: "HTML/CSS, JavaScript, php, python",
    previewLink: "https://evmos.org/",
    githubLink: "https://github.com/LoveNuna/cascadia-chain",
  },
  {
    title: "Ethermint",
    image: projectImage5,
    description: `Ethermint is built on Proof-of-Stake with fast-finality using the Cosmos SDK which runs on top of Tendermint Core consensus engine.`,
    techstack: "Go, Solidity, Python, JavaScript",
    previewLink: "https://docs.evmos.org/",
    githubLink: "https://github.com/LoveNuna/ethermint",
  },
  {
    title: "Car Market",
    image: projectImage6,
    description: `Car Market is Full-Stack project for car sale. It's implement UI/UX and CI/CD`,
    techstack: "React, MongoDB, Node.js",
    previewLink: "https://www.audimidtowntoronto.com/",
    githubLink: "",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "edwardxian062@gmail.com",
};

export const Profile ={
  Profile: profile
}