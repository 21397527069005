import React from "react";
import { techStackDetails } from "../Details";

function Technologies() {
  const {
    Java,  
    JAVAScript,
    Python,
    Solidity,
    ETHEreum,
    REact,
    Node,
    Angular,
    BootStrap,
    CSS,
    HTML,
    MongoDB,
    Postgre,
    VScode,
    Github,
    Git,
    NPM,
    Postman,
  } = techStackDetails;
  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-light-heading light:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        <p className="text-content py-2 lg:max-w-3xl">
          Technologies I've been working with recently
        </p>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={Java} title="Java" alt="" />
        <img src={JAVAScript} title="JAVAScript" alt="" />
        <img src={Python} title="Python" alt="" />
        <img src={Solidity} title="Solidity" alt="" />
        <img src={ETHEreum} title="ETHEreum" alt="" />
        <img src={REact} title="REact" alt="" />
        <img src={Node} title="Node" alt="" />
        <img src={Angular} title="Angular" alt="" />
        <img src={BootStrap} title="BootStrap" alt="" />
        <img src={CSS} title="CSS" alt="" />
        <img src={HTML} title="HTML" alt="" />
        <img src={MongoDB} title="MongoDB" alt="" />
        <img src={Postgre} title="Postgre" alt="" />
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-light-heading light:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={VScode} title="Visual Studio Code" alt="" />
        <img src={Github} title="Github" alt="Figma" />
        <img src={Git} title="Git" alt="NPM" />
        <img src={NPM} title="NPM" alt="Git" />
        <img src={Postman} title="Postman" alt="RStudio" />
      </section>
    </main>
  );
}

export default Technologies;
